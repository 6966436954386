const calendarMonths = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

export const dateRangeArray = (start, end) => {
  const startMs = new Date(start).getTime()
  const endMs = new Date(end).getTime()
  const dayCount = (endMs - startMs) / (24 * 60 * 60 * 1000)
  return [...Array(dayCount)].map((v, i) => new Date(startMs + i * 24 * 60 * 60 * 1000).toISOString().slice(0, 10))
}

export const dateRangeArrayByMonth = (start, end) => {
  const year = start.split('-')[0]
  const startMonth = +start.split('-')[1]
  const endMonth = +end.split('-')[1] + 1
  const monthsRange = [...Array(endMonth - startMonth)].map((value, index) => {
    const monthDates = []
    let firstOfTheMonth = `${year}-${index + 1 >= 10 ? index + 1 : `0${index + 1}`}-01`
    const firstOfNextMonth = `${year}-${index + 2 >= 10 ? index + 2 : `0${index + 2}`}-01`
    const dateNextMonth = index < 11 ? new Date(firstOfNextMonth) : new Date(`${+year + 1}-01-01`)
    const dateEnd = new Date(end)
    while ((new Date(firstOfTheMonth) < dateNextMonth) && (new Date(firstOfTheMonth) <= dateEnd)) {
      monthDates.push(firstOfTheMonth)
      const startJsDate = new Date(firstOfTheMonth)
      startJsDate.setDate(startJsDate.getDate() + 1)
      // following condition to fix leap years and JS compilation errors
      firstOfTheMonth = firstOfTheMonth !== startJsDate.toISOString().slice(0, 10)
        ? startJsDate.toISOString().slice(0, 10)
        : (startJsDate.setDate(startJsDate.getDate() + 1), startJsDate.toISOString().slice(0, 10))
    }
    return monthDates
  })
  return monthsRange
}

export const formatMonthlyData = (month, index) => {
  const monthLabel = calendarMonths[index]
  const enrollmentCount = month.reduce((monthTotal, day) => monthTotal + day?.enrollmentCount ?? 0, 0)
  const projects = month[month.length - 1]?.projects
  const sessionCount = month.reduce((monthTotal, day) => monthTotal + day?.sessionCount ?? 0, 0)
  const totalSessionTimeInSecs = month.reduce((monthTotal, day) => monthTotal + day?.totalSessionTimeInSecs ?? 0, 0)
  return {
    monthLabel,
    enrollmentCount,
    projects,
    sessionCount,
    totalSessionTimeInSecs,
  }
}
