<template>
  <div>
    <v-container
      class="px-6 pt-0 pb-6"
      fluid
    >
      <v-row
        no-gutters
        class="justify-end mt-4 mb-2"
      >
        <div class="d-flex ml-auto my-1 mx-4">
          <export-to-csv
            :file-name-preffix="`${cluster.name} - Subscribers`"
            action-name="cluster/getSubscribersCsv"
            :action-params="{ organizationId, clusterId }"
            :button-text="$t('common.exportToCsv')"
          />
        </div>
      </v-row>
      <section
        id="statsView"
      >
        <v-row no-gutters class="ma-0">
          <v-col
            sm="12"
            md="4"
            class="d-flex flex-column"
          >
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('cluster.statistics.userCount') }}
                </p>
              </div>
              <v-divider
                color="primary"
              />
              <h2 class="text-center my-auto">
                {{ stats.userCount }}
              </h2>
            </v-card>
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('cluster.statistics.projectCount') }}
                </p>
              </div>
              <v-divider
                color="primary"
              />
              <h2
                v-if="stats.projectCount"
                class="text-center my-auto"
              >
                {{ stats.projectCount }}
              </h2>
            </v-card>
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('cluster.statistics.numberEntriesRequested') }}
                </p>
              </div>
              <v-divider
                color="primary"
              />
              <h2 class="text-center my-auto">
                -
              </h2>
            </v-card>
          </v-col>
          <v-col
            sm="12"
            md="4"
            class="d-flex flex-column"
          >
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('cluster.statistics.postCount') }}
                </p>
              </div>
              <v-divider
                color="primary"
              />
              <h2 class="text-center my-auto">
                {{ stats.postCount }}
              </h2>
            </v-card>
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('cluster.statistics.pushNotificationCount') }}
                </p>
              </div>
              <v-divider
                color="primary"
              />
              <h2 class="text-center my-auto">
                {{ stats.pushNotificationCount }}
              </h2>
            </v-card>
            <v-card
              class="d-flex flex-column"
            >
              <div
                class="d-flex mb-1 mt-3"
              >
                <p class="text-start mb-0 mr-2">
                  {{ $t('cluster.statistics.chatCount') }}
                </p>
              </div>
              <v-divider
                color="primary"
              />
              <h2 class="text-center my-auto">
                {{ stats.chatCount }}
              </h2>
            </v-card>
          </v-col>
          <v-col
            sm="12"
            md="4"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="mb-0 mr-2">
                  {{ $t('cluster.statistics.usersByAge') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.age')"
                />
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="usersByAge"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div class="py-4">
                <bar-chart
                  v-if="formattedStats.usersByAge.data.length"
                  :chartdata="formattedStats.usersByAge.data"
                  :labels="formattedStats.usersByAge.labels"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="ma-0">
          <v-col
            sm="12"
            md="4"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="mb-0 mr-2">
                  {{ $t('cluster.statistics.usersByProject') }}
                </p>
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="usersByProject"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div class="py-4">
                <bar-chart
                  v-if="formattedStats.usersByProject.data.length"
                  :chartdata="formattedStats.usersByProject.data"
                  :labels="formattedStats.usersByProject.labels"
                />
              </div>
            </v-card>
          </v-col>
          <v-col
            sm="12"
            md="8"
            class="d-flex flex-column"
          >
            <v-card
              class="overflow-y-auto justify-space-around"
            >
              <div class="d-flex mb-1 mt-3">
                <p class="text-start mb-0 mr-2">
                  {{ $t('cluster.statistics.usersByCountry') }}
                </p>
                <global-tooltip
                  :text="$t('organization.statistics.tooltip.country')"
                />
                <div class="d-flex ml-auto">
                  <modal-chart
                    class="ma-0"
                    category="usersByCountry"
                    :data="formattedStats"
                  />
                </div>
              </div>
              <v-divider
                color="primary"
              />
              <div
                class="py-4"
                style="overflow-x: auto;
  white-space: nowrap;"
              >
                <vert-bar-chart
                  v-if="formattedStats.usersByCountry.data.length"
                  :chartdata="formattedStats.usersByCountry.data"
                  :labels="formattedStats.usersByCountry.labels"
                  :locale="locale"
                  :style="{
                    width: `${formattedStats.usersByCountry.labels.length * 7}%`
                  }"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { countries } from 'countries-list'
import i18nCountries from '@/utils/mixins/i18nCountries'
import { dateRangeArray, dateRangeArrayByMonth, formatMonthlyData } from '@/utils/charts/dateRangeArray'

export default {
  name: 'Stats',
  components: {
    BarChart: () => import('@/utils/charts/horizBars'),
    VertBarChart: () => import('@/utils/charts/vertBars'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
    ModalChart: () => import('@/modules/stats/ModalChart'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [i18nCountries],
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, required: true },
  },
  data: () => ({ locale: null }),
  computed: {
    ...mapGetters({ cluster: 'cluster/data' }),
    ...mapGetters('cluster', ['stats', 'projects']),
    formattedStats({ stats }) {
      return {
        usersByAge: this.formatUsersByAge(stats.usersByAge),
        usersByCountry: this.formatUsersByCountry(),
        usersByProject: this.formatUsersByProject(stats.projectsWithMostUsers),
      }
    },
    formatCountries({ stats: { usersByCountry } }) {
      return Object.entries(usersByCountry)
        .filter(country => country[1])
        .sort((a, b) => b[1] - a[1])
        .map(country => ({ country: { ...countries[country[0]], name: this.countries[country[0]] }, value: country[1] }))
    },
  },
  async created() {
    this.$store.commit('loader/show')
    const { organizationId, clusterId } = this
    if (!this.$store.state.cluster.dbStats) await this.$store.dispatch('cluster/bindStats', { organizationId, clusterId })
    this.$store.commit('loader/hide')
  },
  updated() {
    this.locale = this.$i18n.locale
  },
  methods: {
    formatUsersByAge: stats => {
      const labels = ['1-7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17-18', '19-20', '21-25', '26-30', '31-40', '41-50', '50-100']
      const labelsAndCounts = labels.map(label => ({
        label,
        count: label.includes('-')
          ? Object.entries(stats)
            .filter(([age]) => +age >= +label.split('-')[0] && +age <= +label.split('-')[1])
            .reduce((acc, [, count]) => acc + count, 0)
          : stats[label],
      }))
      return {
        labels: labelsAndCounts.map(({ label }) => label),
        data: labelsAndCounts.map(({ count }) => count),
      }
    },
    formatUsersByCountry() {
      return {
        labels: this.formatCountries.map(country => country.country),
        data: this.formatCountries.map(country => country.value),
      }
    },
    formatUsersByProject(stats) {
      const labels = stats.map(({ id }) => {
        const project = this.projects.find(p => p.id === id)
        return project.name
      })
      const data = stats.map(project => project.userCount)
      return { labels, data }
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-divider {
  border-color: #2acccc;
}

.v-card {
  flex: 1;
  margin: 12px;
  padding: 0 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
}
</style>
